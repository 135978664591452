<template>
  <van-dropdown-item ref="deptDrop" :title="deptName ? deptName : '选择科室'">
    <van-cell>
      <template #right-icon>
        <DeptTree :type="2" @getDept="getDept" />
      </template>
    </van-cell>
  </van-dropdown-item>
</template>

<script>
  import DeptTree from '@/components/DeptTree'
  export default {
    name: 'Index',
    components: { DeptTree },
    data() {
      return {
        deptName: '',
      }
    },

    methods: {
      //选择科室
      getDept(obj) {
        this.deptName = obj.name
        this.$refs.deptDrop.toggle(false)
        this.$emit('getDept', obj)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-dropdown-menu {
    width: 95%;
    margin: auto;
    overflow: hidden;
    .van-collapse-item {
      background: #000;
      .level3::after {
        border-bottom: 0;
      }
    }
  }

  .van-sidebar-item--select::before {
    background-color: #1691fe;
  }
  .van-sidebar-item--select,
  .van-sidebar-item--select:active {
    color: #1691fe;
  }
</style>
