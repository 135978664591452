<template>
  <div>
    <van-dropdown-menu :overlay="false">
      <DeptSelect ref="deptSelect" @getDept="getDept" />
      <DoctorSelect ref="doctorSelect" />
    </van-dropdown-menu>
    <DateSelect @getDate="getDate" />
    <div class="docList">
      <van-radio-group v-model="radio" class="title" direction="horizontal">
        <van-radio
          v-for="(item, index) in radioOptions"
          :key="index"
          :name="item.value"
          @click="changeTitle(item)"
        >
          {{ item.label }}
        </van-radio>
      </van-radio-group>
      <van-cell-group v-if="list.length > 0">
        <van-cell v-for="(item, index) in list" :key="index">
          <van-card @click="toInfo(item)">
            <template #title>
              <span class="name">{{ item.doctorName }}</span>
              &nbsp;
              <span class="job">{{ item.title }}</span>
              <div class="number">
                {{ item.count >= 100 ? '余99+' : '余' + item.count }}
              </div>
              <br />
              <span class="dept">{{ item.deptName }}</span>
              <br />
            </template>
            <template #thumb>
              <van-image
                v-if="item.picture !== ''"
                round
                width="2rem"
                height="2rem"
                fit="contain"
                :src="'data:image/png;base64,' + item.picture"
              />
              <van-image
                v-else
                round
                width="2rem"
                height="2rem"
                :src="require('@/assets/dept_images/Doctor_photo.png')"
              />
            </template>
            <template #desc>
              <p class="desc">
                <van-image
                  width="0.8rem"
                  :src="require('@/assets/dept_images/sc.png')"
                />
                {{ item.des }}
              </p>
            </template>
          </van-card>
        </van-cell>
      </van-cell-group>
      <van-empty v-else description="暂无医生排班，请重新查询" />
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { Toast } from 'vant'
  import DeptSelect from '@/components/DeptSelect'
  import DateSelect from '@/components/DateSelect'
  import DoctorSelect from '@/components/DoctorSelect'
  import { getDayInfo } from '@/api/basic/day/day'
  import { getDoctorScheduleList } from '@/api/his/his'

  export default {
    name: 'Index',
    components: { DeptSelect, DateSelect, DoctorSelect },
    data() {
      return {
        radio: 2,
        day: 14,
        search: {
          DeptCode: '',
          DoctorCode: '',
          BeginTime: '',
          EndTime: '',
          Type: 2,
        },
        list: [],
        radioOptions: [
          {
            value: 2,
            label: '全部',
          },
          {
            value: 1,
            label: '专家',
          },
          {
            value: 0,
            label: '普通',
          },
        ],
      }
    },
    async created() {
      this.search.DeptCode = this.$Base64.decode(this.$route.query.deptCode)
      await this.pageInit()
      await this.fetchData()
    },
    mounted() {},

    methods: {
      //初始化
      async pageInit() {
        const { data } = await getDayInfo({ ID: 1 })
        this.day = data.day
        this.search.BeginTime = moment(new Date()).format('yyyy-MM-DD')
        this.search.EndTime = moment(new Date())
          .add(this.day - 1, 'day')
          .format('yyyy-MM-DD')
        this.$refs['doctorSelect'].setTime(
          this.search.BeginTime,
          this.search.EndTime
        )
      },

      //列表获取
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getDoctorScheduleList(this.search)
        this.list = data
        Toast.clear()
      },

      //0全部 1专家 2普通
      changeTitle(val) {
        this.search.Type = val.value
        this.fetchData()
      },

      toInfo(item) {
        this.$router.push({
          path: '/scheduleDetail',
          query: {
            deptCode: this.$Base64.encode(item.deptCode),
            doctorCode: this.$Base64.encode(item.doctorCode),
            deptName: this.$Base64.encode(item.deptName),
            doctorName: this.$Base64.encode(item.doctorName),
            beginTime: this.$Base64.encode(this.search.BeginTime),
            endTime: this.$Base64.encode(this.search.EndTime),
            fee: this.$Base64.encode(item.fee),
          },
        })
      },

      //科室重新选择
      getDept(obj) {
        this.search.DeptCode = obj.code
        this.fetchData()
      },

      //时间点击时间
      getDate(obj) {
        let date = obj.dateAll
        switch (date) {
          case 0:
            this.search.BeginTime = moment(new Date()).format('yyyy-MM-DD')
            this.search.EndTime = moment(new Date())
              .add(this.day - 1, 'day')
              .format('yyyy-MM-DD')
            break
          default:
            this.search.BeginTime = moment(date).format('yyyy-MM-DD')
            this.search.EndTime = moment(date).format('yyyy-MM-DD')
            break
        }
        this.fetchData()
        this.$refs['doctorSelect'].setTime(
          this.search.BeginTime,
          this.search.EndTime
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-dropdown-menu {
    width: 95%;
    margin: auto;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 10px;
    ::v-deep .van-dropdown-item {
      top: 48px !important;
      width: 95%;
      margin: auto;
      .van-dropdown-item__content {
        height: 100vh;
        max-height: none;
      }
    }
  }
  .docList {
    width: 95%;
    margin: auto;
    margin-top: 0.4rem;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    .title {
      padding: 0.426rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
    }
    .van-card {
      background: #fff;
      font-size: 0.426rem;
      line-height: 1.5;
      .name {
        font-weight: bold;
      }
      .job {
        font-size: 0.38rem;
        color: #616161;
      }
      .dept {
        color: #616161;
      }
      .number {
        background: #1691fe;
        width: fit-content;
        padding: 0 7px;
        color: #fff;
        line-height: 30px;
        border-radius: 15px;
        float: right;
      }
      .desc {
        margin: 0;
        font-size: 0.38rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .van-dropdown-menu {
    ::v-deep .van-dropdown-menu__title--active {
      color: #1691fe;
    }
  }
</style>
